/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 20, 2021 */



@font-face {
    font-family: 'montserratregular';
    src: url('Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratbold';
    src: url('Montserrat-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}
