/*login*/

div#login_wrapper {
    width: 100%;
    float: left;
    position: relative;
}

.wrapper_guard {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.login_banner {
    width: calc(100% - 600px);
    display: flex;
    flex-direction: column;
}

.login_forms {
    width: 600px;
    background: white;
    display: flex;
    padding: 30px 40px !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login_banner img {
    width: 100%;
    height: calc(100vh);
    object-fit: cover;
    object-position: center;
}

.login_forms_wrapper {
    width: 100%;
    float: left;
}

.logo_header img {
    width: 180px;
}

.login_contents {
    width: 100%;
    float: left;
    margin-top: 80px;
    padding: 0px 30px;
}

.logo_Title {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 25px;
    color: #ABABAC;
}

.flex_froms .form-group {
    border: 1px solid #D1D1D1;
    margin-bottom: 0px;
    padding: 20px 20px 0px 20px;
    position: relative;
}

.flex_froms .form-group input {
    font-weight: 400 !important;
    width: 80%;
}

.flex_froms input {
    width: unset;
}

.flex_froms label {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #3B3D42;
    display: flex;
    align-items: center;
}

.flex_froms .form-group span.toggle-password {
    position: absolute;
}

.flex_froms .form-group span.toggle-password {
    position: absolute;
    top: 50%;
    right: 25px;
}

.login_forms .form-group label {
    color: #ABABAC;
}

.styled-checkbox+label {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 0;
}

.styled-checkbox:checked+label:before {
    background: #7ACCCE !important;
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    color: white !important;
    border: 1px solid #ddd;
    border-radius: 3px;
}

.styled-checkbox+label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 3px;
}

.styled-checkbox:checked+label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}

.form_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.flex_froms .btn_grp {
    display: flex;
    justify-content: space-between;
}

.flex_froms .btn_submits {
    width: 150px !important;
    height: 40px !important;
}

.flex_froms .btn_grp a {
    margin-left: 0px;
}

.forgot_forms .logo_Title {
    font-size: 20px !important;
}

img {
    vertical-align: middle;
}

.login_banner img {
    width: 100%;
    height: calc(100vh);
    object-fit: cover;
    object-position: center;
}

@media screen and (min-width: 992px) and (max-width: 1600px) {
    div#login_wrapper .login_banner {
        width: calc(100% - 540px);
    }
}

@media screen and (min-width: 992px) and (max-width: 1280px) {}

@media (max-width:1269px) {
    .login_banner {
        display: none !important;
    }
    .wrapper_guard {
        flex-direction: column !important;
        align-items: center;
    }
}


/*login*/

div#login_wrapper {
    width: 100%;
    float: left;
    position: relative;
}

.wrapper_guard {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.login_banner {
    width: calc(100% - 600px);
    display: flex;
    flex-direction: column;
}

.login_forms {
    width: 600px;
    background: white;
    display: flex;
    padding: 15px 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.login_banner img {
    width: 100%;
    height: calc(100vh);
    object-fit: cover;
    object-position: center;
    height: 100%;
}

.login_forms_wrapper {
    width: 100%;
    float: left;
}

.logo_header img {
    width: 180px;
}

.login_contents {
    width: 100%;
    float: left;
    margin-top: 80px;
    padding: 0px 30px;
}

.logo_Title {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 25px;
    color: #ABABAC;
}

.flex_froms .form-group {
    border: 1px solid #D1D1D1;
    margin-bottom: 0px;
    padding: 20px 20px 0px 20px;
    position: relative;
}

.flex_froms .form-group input,
.flex_froms .form-group select {
    border: none;
    box-shadow: unset;
    padding-bottom: 25px;
    padding-left: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #3B3D42;
}

.flex_froms label {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #3B3D42;
}

.flex_froms .form-group span.toggle-password {
    position: absolute;
}

.flex_froms .form-group span.toggle-password {
    position: absolute;
    top: 50%;
    right: 25px;
}

.styled-checkbox {
    position: absolute;
    opacity: 0;
}

.styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
}

.styled-checkbox+label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 3px;
}

.styled-checkbox:hover+label:before {
    background: #7ACCCE;
}

.styled-checkbox:focus+label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked+label:before {
    background: #7ACCCE;
}

.styled-checkbox:disabled+label {
    color: #b8b8b8;
    cursor: auto;
}

.styled-checkbox:disabled+label:before {
    box-shadow: none;
    background: #ddd;
}

.styled-checkbox:checked+label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}

.form_footer a {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    color: #24375D;
}

.form_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex_froms {
    width: 100%;
    float: left;
    margin: 50px 0px;
}

.btn_submits {
    width: 180px;
    height: 50px;
    background: linear-gradient(94.41deg, #24375D 0%, #313878 100%);
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 0;
}

.toggleOpen {
    display: none;
}

.toggle-eye-open img.toggleClose {
    display: none;
}

.toggle-eye-open img.toggleOpen {
    display: block;
}

.btn_submits:hover {
    color: #fff;
}

.logo_headerTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #37393C;
    margin-bottom: 15px;
}

.title-sm {
    color: #8B9A9A;
    font-size: 16px !important;
}

.btn_grp a {
    margin-left: 20px;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    text-decoration-line: underline;
    color: #24375D;
    font-weight: 600;
}

.btn_grp {
    width: 100%;
    float: left;
    margin-top: 40px;
}

button.btn.btn_submits:focus {
    background: linear-gradient(94.41deg, #24375D 0%, #313878 100%);
    color: #ffffff;
}

.activeLabel label {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #7ACCCE;
}