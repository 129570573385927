.nav-item .nav-title{
    display: block;
    padding: 1.2rem 2rem;
    font-weight: bold;
}
.nav-link.active {
    font-weight: bold;
    color:rgb(122, 204, 206) !important;
}
.nav-tabs .nav-link {
    padding-bottom: 1rem;
    padding-top: 1rem;
}
.nav-tabs {
    margin-bottom: 1.2rem;
}

._goToright {
    margin-left: auto !important;
}

.nav-tabs .not-nav-link {
     margin-bottom: 0.5rem; 
     margin-top: 0.5rem; 
}
.no_margin{

    margin-right: 10px !important;
    
}

._center img, ._center span {
    margin: 0px 5px;
}
._left
{
    text-align: left !important;
}
@media screen and (min-width: 992px) and (max-width: 1186px)
{
    .nav-item
    {
        font-size: 16px;
    }
    .nav-item .nav-title {
        display: none;
    }
    .nav-tabs .nav-link {
        padding: 0.8rem;
    }
    .btn.new-admin {
        padding: 5px 10px;
        width: auto;

    }
    .nav-tabs .nav-link {
        margin-bottom: -1px;
    }
}


/*Dialog*/

.Dialog_wrapper {
    top: 0;
	width: 100vw;
	background: rgba(10, 10, 10, .4);
	height: 100vh;
	position: fixed;
	transition: all .02s;
	z-index: 9999999999;
	display: none;
}
.Dialog_clear {
    top: 250px;
    position: relative;
	width: 380px;
	margin: 0 auto;
	background: #FFFFFF;
	box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.08);
	border-radius: 15px;
	padding: 30px 20px;
}
.Dialog_content {
    width: calc(100% - 60px);
	margin-left: 10px;
	margin-bottom: 10px;
}
.Dialog_content label{
	font-weight: bold;
	margin-bottom:15px;
}
.Dialog_content textarea{
	margin-bottom:15px;
}
span.Dialog_osff {
	position: absolute;
	right: 10px;	
	background: #fff;
	cursor: pointer;
	border-radius: 10px 0px 0 10px;
	width: 40px;
	height: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	
}

.Dialog_head {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 20px;
	justify-content: space-between;
	padding-bottom: 20px;
	border-bottom: 1px solid #D5D2CD;
}

.Dialog_head h2 {
	font-size: 18px;
	text-transform: uppercase;
	color:#E74C3C;
	font-weight: 900;
}


.Dialog_body {
	width: 100%;
	overflow-y: auto;
}

.Dialog_img {
	width: 40px;
	height: 40px;
	background: #DADADA;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	margin-right: 20px;
}

.Dialog_wrapper.panelactive {
	left: 0;
	display: block;
}
.Dialog_details {
	width: 100%;
	display: flex;
}

/*Dialog popup end*/


