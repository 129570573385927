@import url('https://fonts.googleapis.com/css2?family=Asap&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Asap:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Asap:wght@700&display=swap');
@font-face {
    font-family: 'asapbold';
    src: url('./../fonts/asap-fonts/asap-bold-webfont.woff2') format('woff2'), url('./../fonts/asap-fonts/asap-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'asapregular';
    src: url('./../fonts/asap-fonts/asap-regular-webfont.woff2') format('woff2'), url('./../fonts/asap-fonts/asap-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


/* CSS reset */

body,
div,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: 'montserratregular', sans-serif;
    background-color: white;
    height: 100%;
    min-height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
}

html,
body .notification_wrapper {
    margin: 0;
    padding: 0;
    font-family: 'montserratregular', sans-serif;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    transition: visibility 0s, opacity 0.5s linear;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

fieldset,
img {
    border: 0;
}

input {
    border: 1px solid #b0b0b0;
    padding: 3px 5px 4px;
    color: #979797;
    width: 190px;
}

ol,
ul {
    list-style: none;
}

caption,
th {
    text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
    font-family: 'montserratregular', sans-serif;
}

q:before,
q:after {
    content: '';
}

label {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    cursor: pointer;
}

input,
button,
textarea,
select {
    outline: none !important;
}

a {
    text-decoration: none;
}

a:focus,
a:hover {
    text-decoration: none;
}

button,
a {
    outline: none;
}

.mt-20 {
    margin-top: 20px;
}

.wri-base {
    float: left;
    width: 100%;
}


/*dashboard*/

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* font-family: 'Droid Sans', sans-serif; */
    outline: none;
}

 ::-webkit-scrollbar {
    background: transparent;
    width: 5px;
    height: 5px;
}

 ::-webkit-scrollbar-thumb {
    background-color: #888;
}

 ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

body {
    background-color: #f3f7f9;
    font-family: 'montserratregular', sans-serif;
    height: 100%;
    zoom: 90%;
}

#contents {
    position: relative;
    transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
    margin-left: 260px !important;
    background-color: #F3F7F9;
    -moz-transform: scale(0.98);
}

#contents nav {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
    line-height: 3em;
}

.margin {
    margin-left: 0 !important;
}

.wrapper_contentBlock {
    background: #F3F7F9;
}


/* Start side navigation bar  */

.d-flex {
    background-color: #24375D;
}

.side-nav {
    float: left;
    height: 100%;
    width: 260px;
    background-color: #24375D;
    color: #CCC;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: .3s;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    z-index: 9999999;
}

.side-nav .close-aside {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
    color: #EEE;
}

.heading {
    background-color: #24375D;
    padding: 15px 15px 15px 20px;
    overflow: hidden;
    border-bottom: 1px solid #24375D;
}

.heading>img {
    float: left;
}

.info {
    float: left;
    width: 69%;
    margin-left: 3%;
}

.heading .info>h3 {
    margin: 0 0 5px;
}

.heading .info>h3>a {
    color: #EEE;
    font-weight: 100;
    margin-top: 4px;
    display: block;
    text-decoration: none;
    font-size: 18px;
}

.heading .info>h3>a:hover {
    color: #FFF;
}

.heading .info>p {
    color: #BBB;
    font-size: 13px;
}


/* End heading */


/* Start search */

.search {
    text-align: center;
    padding: 15px 30px;
    margin: 15px 0;
    position: relative;
}

.search>input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #23262d;
    padding: 7px 0 7px;
    color: #DDD
}

.search>input~i {
    position: absolute;
    top: 22px;
    right: 40px;
    display: block;
    color: #2b2f3a;
    font-size: 19px;
}


/* End search */

.categories>li {
    padding: 17px 13px 17px 13px;
    overflow: hidden;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.categories>li>a {
    color: #96ACD9 !important;
    text-decoration: none;
}


/* Start num: there are three options primary, danger and success like Bootstrap */

.categories>li>a>.num {
    line-height: 0;
    border-radius: 3px;
    font-size: 14px;
    color: #FFF;
    padding: 0px 5px
}

.dang {
    background-color: #f35959
}

.prim {
    background-color: #0275d8
}

.succ {
    background-color: #5cb85c
}


/* End num */

.categories li a:hover,
.categories ul li a:hover {
    color: #FFF
}

.categories>li>i {
    font-size: 18px;
    margin-right: 5px
}

.categories>li>a {
    width: 100%;
}

.categories>li.submenu>a:after {
    position: relative;
    top: 8px;
    content: "\f053";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 11px;
    line-height: 1.8;
    float: right;
    color: #96ACD9;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.categories .submenu.opend>a:after {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg);
}


/* End categories */


/* Start dropdown menu */

.categories .side-nav-dropdown {
    padding-top: 10px;
    padding-left: 30px;
    list-style: none;
    display: none;
}

.side-nav .categories .side-nav-dropdown>li>a {
    color: #96ACD9 !important;
    text-decoration: none;
    padding: 7px 0;
    display: block;
}

.side-nav .categories p {
    margin-left: 14px;
    margin-top: 10px;
    margin-bottom: 7px;
    color: #7e90b6 !important;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    font-weight: bold;
}

.categories li a:hover,
.categories ul li a:hover {
    color: #FFF !important;
}

.side-nav .categories>li a:hover svg path {
    fill: #FFF;
}

.categories li a:hover,
.categories ul li a:active {
    color: #FFF !important;
}

.side-nav-dropdown p {
    margin-left: 0px !important;
}


/* End dropdown menu */

.show-side-nav {
    -webkit-transform: translateX(-300px);
    -moz-transform: translateX(-300px);
    transform: translateX(-300px);
}

.side-nav .categories>li.active {
    background: #2E4678;
}

ul.categories li.active a {
    color: #ffffff !important;
}

.side-nav .categories>li>a svg {
    vertical-align: bottom;
    margin-right: 10px;
}

a.dropdown-toggle img.porfileimg {
    /* border-radius: 50%; */
    width: 55px;
    /* border: 1px solid #a3a3a3; */
    margin-right: 10px;
}

.profileTxt h6 {
    font-size: 14px;
    line-height: 21px;
    color: #3B3F45;
}

.profileTxt small {
    font-size: 13px;
    line-height: 18px;
    color: #A6ACB8;
}

.spanTitle {
    position: absolute;
    left: 60px;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    color: #27282C;
}

.divider {
    border-left: 1px solid rgb(204, 200, 200);
    height: 30px;
    margin-right: 25px;
}

li.dropdown small.dropdown_arrow {
    position: relative;
    top: -1px;
}

.navbar-default .navbar-nav>li>a {
    color: #EEE !important;
    line-height: 55px !important;
    padding: 0 10px !important;
}

.profileTxt {
    display: flex;
    flex-direction: column;
}

small.dropdown_arrow img {
    width: 13px;
    margin-left: 10px;
}

.notification i {
    color: #687286;
    font-size: 25px !important;
}

.navbar-expand-lg .navbar-nav {
    flex-direction: row;
    align-items: center;
}

.myprofile_detail_wrapper {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0;
    border-bottom: 1px solid #DFE6E6;
}

.myprofile_img img {
    width: 40px;
    height: 40px;
    border: 1px solid #dfe6e6;
    object-fit: cover;
    object-position: center;
    border-radius: 25px;
}

a.dropdown-item img {
    float: right;
}

.myprofile_content {
    padding-left: 10px;
    width: 100%;
}

.myprofile_content h3 {
    font-size: 16px;
    color: #0D1426;
    font-weight: 900;
    margin-bottom: 5px;
}

.myprofile_content span {
    font-size: 12px;
    color: #838485;
}

.myprofile_detail_wrapper>i {
    font-size: 26px;
    color: #8A8B8C;
    float: right;
}

.myprofile_detail_wrapper i.fas.fa-angle-right {
    position: absolute;
    right: 30px;
    top: 17px;
}

.myprofile_detail_wrapper {
    position: relative;
}

.bold-txt {
    font-weight: bold;
}


/*bootstrap edits*/

a.dropdown-toggle {
    width: 230px;
    display: flex !important;
    align-items: center;
}

li.borderSeperate {
    margin-right: 25px;
    /* padding-right: 25px; */
}

.Spanalert {
    background-color: #E74C3C!important;
    border-radius: 50% !important;
    height: 14px !important;
    width: 14px !important;
    padding: 0px !important;
    font-size: 11px !important;
    position: absolute !important;
    top: 8px !important;
    display: inline-block;
    line-height: 13px;
    color: #ffffff;
    text-align: center;
}

li.notifyHeader {
    position: relative;
}

.menu-btn {
    color: #fff;
    padding-left: 12px;
    padding-right: 12px;
    background-color: #7ACCCE !important;
    border: 1px solid transparent !important;
    box-shadow: none !important;
    border-radius: 5px;
    line-height: 35px;
}

.mat-bb {
    border-top: 1px solid #D7DDE6;
    margin: 20px 0px;
}

.mat-bold {
    width: 100%;
    border-top: 1px solid #7a7b7e;
    margin: 20px 0px;
}


/*notification*/

.notification_wrapper {
    width: 124vw;
    ;
    background: rgba(10, 10, 10, .4);
    height: 100vh;
    position: absolute;
    left: 0%;
    top: 0;
    transition: all .02s;
    z-index: 9;
    display: block;
}

.notification_clear {
    position: absolute;
    width: 30%;
    right: 0;
    height: 100%;
    background: #FFFFFF;
    box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.08);
    border-radius: 20px 0px 0px 20px;
    padding: 30px 20px;
    -moz-margin-end: 10%;
}

span.notifi_osff {
    position: absolute;
    left: -40px;
    top: 30px;
    background: #fff;
    cursor: pointer;
    border-radius: 10px 0px 0 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.notifi_head {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #D5D2CD;
}

.notifi_head h2 {
    font-size: 18px;
    text-transform: uppercase;
    color: #2D2F31;
    font-weight: 900;
}

.notifi_head span {
    font-size: 16px;
    text-decoration-line: underline;
    color: #838587;
    cursor: pointer;
}

.listout_notifi {
    width: 100%;
    overflow-y: auto;
    float: left;
    height: calc(100vh - 1px);
}

.listout_notifi ul li {
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;
    padding-bottom: 20px;
    border-bottom: 1px solid #D5D2CD;
}

.notifi_details {
    width: 100%;
    display: flex;
}

li.active .noitifi_img {
    background: #25D4E3;
}

.noitifi_img {
    width: 40px;
    height: 40px;
    background: #DADADA;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-right: 20px;
}

.notifi_content {
    width: calc(100% - 60px);
}

.notifi_content h4 {
    font-size: 16px;
    line-height: 19px;
    color: #2D2F31;
    font-weight: 600;
    margin-bottom: 3px;
}

.notifi_content span {
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #9F9E9B;
    display: inline-block;
    width: 100%;
}

.notifi_content p {
    font-size: 15px;
    line-height: 18px;
    color: #616568;
    max-width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
}

.notification_wrapper.panelactive {
    left: 0;
    display: block;
    transition: opacity 1s ease-out;
}


/*notification popup end*/

.navbar-expand-lg .navbar-nav .dropdown-menu {
    left: -60px;
    border: 0 !important;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
}

.myprofile_detail_wrapper {
    line-height: 1em;
}

.dropdown-menu[data-bs-popper] {
    margin-top: 0.6rem !important;
}

.p-3 {
    padding: 0rem!important;
}

.dropdown-menu {
    min-width: 18rem;
    line-height: 2em;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .8rem;
    padding-left: .8rem;
}

.dropdown-toggle::after {
    display: none;
}

.wrapper {
    display: flex;
    align-items: stretch;
    perspective: 1500px;
}

#sidebar {
    transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
    transform-origin: center left;
    /* Set the transformed position of sidebar to center left side. */
}

#sidebar.active {
    margin-left: -300px;
    /* Rotate sidebar vertically by 100 degrees. */
}

#contents.active {
    transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
    margin-left: 0 !important;
}

.EditColor_ {
    background-color: #EAF9F9 !important;
    color: #000 !important;
    border: none !important;
}

.alertTitle_ {
    font-weight: bold;
    color: #000;
    font-size: 18px;
}


/*Delete Tutor*/

.DTutor_wrapper {
    top: 0;
    width: 100vw;
    background: rgba(10, 10, 10, .4);
    height: 100vh;
    position: fixed;
    transition: all .02s;
    z-index: 9999999999;
    display: none;
}

.DTutor_clear {
    top: 250px;
    position: relative;
    width: 380px;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    padding: 30px 20px;
}

.DTutor_content {
    width: calc(100% - 60px);
    margin-left: 10px;
    margin-bottom: 10px;
}

.DTutor_content label {
    font-weight: bold;
    margin-bottom: 15px;
}

span.DTutor_osff {
    position: absolute;
    right: 10px;
    background: #fff;
    cursor: pointer;
    border-radius: 10px 0px 0 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.DTutor_head {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #D5D2CD;
}

.DTutor_head h2 {
    font-size: 18px;
    text-transform: uppercase;
    color: #E74C3C;
    font-weight: 900;
}

.DTutor_body {
    width: 100%;
    overflow-y: auto;
}

.DTutor_img {
    width: 40px;
    height: 40px;
    background: #DADADA;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-right: 20px;
}

.DTutor_wrapper.panelactive {
    left: 0;
    display: block;
}

.DTutor_details {
    width: 100%;
    display: flex;
}


/*DTutor popup end*/

@media (max-width: 900px) {
    .spanTitle {
        display: none;
        transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
    }
    #navbarSupportedContent {
        transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
        /* margin-right: -350px; */
    }
    #navbarSupportedContent.active {
        transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
        margin-right: 0px;
    }
    .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        margin-top: 1.2rem !important;
    }
}

.background-base {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 30px 30px 30px!important;
}

.manage-adm-top h4 {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
}

.manage-adm-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper_contentBlock {
    width: 100%;
    float: left;
    padding: 10px;
}

@media screen and (max-width :560px) {
    #sidebar.mobile-active {
        margin-left: -300px;
        /* Rotate sidebar vertically by 100 degrees. */
    }
    #contents.mobile-active {
        transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
        margin-left: 0 !important;
    }
    #sidebar.mobile-active-open {
        width: 100% !important;
        margin-left: 0px;
        /* Rotate sidebar vertically by 100 degrees. */
    }
}

:host::ng-deep.fc-center {
    font-size: 2vmin !important;
}