/* You can add global styles to this file, and also import other style files */

::ng-deep .p-dialog .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 0 1.5rem 2rem 1.5rem;
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

::ng-deep.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 1.5rem;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
}

.fc .fc-toolbar .fc-button:enabled:hover {
    background: #ffffff !important;
    color: #757575;
    border-color: #ffffff;
}

.fc .fc-toolbar .fc-button:d {
    background: #ffffff !important;
    color: #757575 !important;
    border-color: #ffffff !important;
}

.fc .fc-toolbar .fc-button:active {
    background: #ffffff !important;
    color: #757575 !important;
    border-color: #ffffff !important;
}

.fc .fc-toolbar .fc-button {
    color: #757575 !important;
    background: #ffffff !important;
    border: 1px solid #ffffff !important;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.p-component * {
    box-sizing: border-box;
    font-family: 'montserratregular', sans-serif;
}

.p-inputswitch .p-inputswitch-slider {
    background: #E74C3C !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
    border-radius: 30px !important;
}

.p-inputswitch .p-inputswitch-slider:before {
    background: #ffffff !important;
    width: 0.90rem !important;
    height: 0.90rem !important;
    left: 0.25rem !important;
    margin-top: -0.45rem !important;
    border-radius: 50% !important;
    transition-duration: 0.2s !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #2ECC71 !important;
}

.has-error {
    border-color: rgb(243, 115, 115);
    color: rgb(243, 115, 115);
}

.w-btn-danger {
    color: #fff;
    background-color: #E74C3C;
    border-color: #E74C3C;
}

.w-btn-danger:hover {
    color: #fff;
    background-color: #E74C3C;
    border-color: #E74C3C;
}

.w-btn-danger:active {
    color: #fff;
    background-color: #E74C3C;
    border-color: #E74C3C;
}

.w-btn-success {
    color: #fff;
    background-color: #2ECC71;
    border-color: #2ECC71;
}

.w-btn-success:hover {
    color: #fff;
    background-color: #2ECC71;
    border-color: #2ECC71;
}

.w-btn-scondary {
    color: #fff;
    background-color: #24375D;
    border-color: #24375D;
}

.w-btn-scondary:hover {
    color: #fff;
    background-color: #24375D;
    border-color: #24375D;
}

.w-btn-success:active {
    color: #fff;
    background-color: #2ECC71;
    border-color: #2ECC71;
}

.w-btn-info {
    color: #fff;
    background-color: #7ACCCE;
    border-color: #7ACCCE;
}

.w-btn-info:hover {
    color: #fff;
    background-color: #7ACCCE;
    border-color: #7ACCCE;
}

.w-btn-outline-info {
    color: #34C5C9;
    background-color: #EBF7F8;
    border-color: #EBF7F8;
}

.btn-110 {
    width: 110px;
}

:host ::ng-deep .custom-spinner .p-progress-spinner-circle {
    animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite !important;
}

.store {
    font-size: 2.2vmin !important;
}

.visit-btn {
    letter-spacing: 1px;
    font-size: 2.2vmin !important;
}

.appel {
    width: 2.5vmin !important;
    max-height: 25px !important;
}

.min-h {
    min-height: calc(100% - 163px);
}

@keyframes custom-progress-spinner-color {
    100%,
    0% {
        stroke: #16697A !important;
    }
    40% {
        stroke: #16697A !important;
    }
    66% {
        stroke: #16697A !important;
    }
    80%,
    90% {
        stroke: #16697A !important;
    }
}

@keyframes custom-progress-spinner-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/themes/saga-blue/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
.p-button:enabled:active {
    background: transparent !important;
    background-color: aliceblue;
    border-left: none;
    border-color: lightgray !important;
    color: #b3aeae;
}

.p-button:enabled {
    background: transparent !important;
    background-color: aliceblue;
    border-left: none;
    border-color: lightgray !important;
    color: #b3aeae;
}

.p-button:disabled {
    background: transparent !important;
    background-color: aliceblue;
    border-left: none;
    border-color: lightgray !important;
    color: #b3aeae;
}

.p-button:enabled:hover {
    background: transparent !important;
    color: #b3aeae !important;
    border-color: aliceblue;
}

.p-calendar .p-inputtext {
    flex: 1 1 auto;
    width: 1%;
    border-right: none !important;
}

.p-calendar .p-inputtext:enabled:hover {
    /* border-color: #2196F3; */
    border: 1px solid #ced4da;
}

.p-calendar .p-inputtext:enabled:active {
    /* border-color: #2196F3; */
    border: 1px solid #ced4da;
}

.session-status {
    font-size: 100% !important;
}

.p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
    color: black;
    padding: 4px;
    border-radius: 2px;
}

.p-menuitem-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    overflow: hidden;
    padding: 4px;
    position: relative;
}

.seconed-col {
    margin-right: 10%;
    width: 40%;
}

.image-ch {
    background-position: center;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.image-ch img {
    width: 100%;
    height: 100%;
}

.text-seconde {
    font-size: 2.5vmin;
    color: white;
    word-spacing: 3px;
}

.contact-input {
    background-color: #192144;
    border: 1px solid #192144;
    color: #7ACCCE;
}

.forget-link {
    font-size: 12px;
    color: #D1D1D1;
    cursor: pointer;
}

.first-col {
    margin-left: 10%;
    width: 40%;
}

.first-col-login {
    /* margin-left: 10%;
    width: 40%; */
    margin-left: 10%;
    width: 40%;
    background-image: url(assets/images/websiteImages/img8.png);
    background-repeat: repeat-y;
    background-position-x: center;
    max-height: 70vh;
}

.bg-websit {
    background-color: #24375D;
}

.btn-font-size {
    font-size: 13px;
}

.cursor-pointer {
    cursor: pointer;
}

.text-main {
    color: white;
    font-size: 4.5vmin;
    font-weight: bolder;
}

.w-input-group {
    background-color: #192144;
    color: #69747d;
    border: solid 1px #192144;
    border-radius: .25rem 0rem 0rem .25rem;
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
}

.w-input-group i {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}

.logo {
    width: 10%;
    left: 10%;
    position: relative;
}

.logo-ltr {
    width: 10%;
    left: 10%;
    position: relative;
}

.logo-rtl {
    width: 10%;
    left: 80% !important;
    position: relative;
}

.btn-store {
    left: -2%;
    position: relative;
}

.footer {
    font-weight: bolder;
    padding: 1rem;
    background-color: #24375D;
    color: white;
    letter-spacing: .5px;
    height: 96px;
    align-content: center;
    min-width: 100%;
    max-width: 100%;
    margin: 0px;
}

.footer .navs {
    /* place-content: end; */
    font-size: 2vmin;
}

.login-text {
    font-size: 2vmin;
}

@media screen and (max-width: 600px) {
    .seconed-col {
        width: 100%;
        margin-top: 20px;
    }
    .first-col {
        width: 100%;
        margin-top: 20px;
        margin-left: 0px;
    }
    .first-col-login {
        width: 100%;
        margin-top: 20px;
        margin-left: 0px;
    }
}

@media screen and (max-width: 990px) {
    .seconed-col {
        width: 100%;
        margin-top: 20px;
    }
    .first-col {
        width: 100%;
        margin-top: 20px;
        margin-left: 0px;
    }
    .first-col-login {
        width: 100%;
        margin-top: 20px;
        margin-left: 0px;
    }
}

.tutor-image {
    position: relative;
    top: 5%;
    width: 100%;
}

@media screen and (max-width: 320px) {
    .login-text {
        font-size: 4vmin;
    }
    .logo {
        width: 20%;
    }
    .app-padding {
        font-size: 3.5vmin !important;
    }
    .appel {
        width: 5vmin !important;
    }
    .column-display {
        display: none;
    }
    .text-main {
        color: white;
        font-weight: bolder;
        padding-inline: 3rem;
        font-size: 5.5vmin;
    }
    .text-seconde {
        font-size: 4vmin;
        padding-inline: 3rem;
    }
    .btn-store .group {
        font-size: 6px;
    }
    .btn-store .group img {
        width: 50%;
    }
    .store-btn {
        justify-content: center;
    }
    .footer {
        font-size: 6px;
        font-weight: bolder;
        padding: 1rem;
        position: relative;
        top: 0%;
    }
    .footer .navs {
        /* place-content: end; */
        font-size: 3vmin;
    }
}

@media screen and (max-width: 480px)and (min-width: 320px) {
    .login-text {
        font-size: 4vmin;
    }
    .logo {
        width: 20%;
    }
    .app-padding {
        font-size: 3.5vmin !important;
    }
    .appel {
        width: 5vmin !important;
    }
    .column-display {
        display: none;
    }
    .btn-font-size {
        font-size: 10px;
    }
    .text-main {
        color: white;
        font-weight: bolder;
        padding-inline: 3rem;
        font-size: 5.5vmin;
    }
    .text-seconde {
        padding-inline: 3rem;
        font-size: 4.5vmin;
    }
    .btn-store .group {
        font-size: 6px;
    }
    .btn-store .group img {
        width: 50%;
    }
    .store-btn {
        justify-content: center;
    }
    .store {
        font-size: 4.2vmin !important;
    }
    .visit-btn {
        font-size: 4.2vmin !important;
    }
    .footer {
        font-size: 6px;
        font-weight: bolder;
        padding: 1rem;
        position: relative;
        top: 0%;
    }
    .footer .navs {
        /* place-content: end; */
        font-size: 3vmin;
    }
}

@media screen and (max-width: 768px)and (min-width: 480px) {
    .login-text {
        font-size: 4vmin;
    }
    .logo {
        width: 15%;
    }
    .tutor-image {
        width: 70%;
    }
    .column-display {
        display: none;
    }
    .btn-font-size {
        font-size: 12px;
    }
    .text-main {
        color: white;
        font-weight: bolder;
        padding-inline: 3rem;
        font-size: 5vmin;
    }
    .text-seconde {
        padding-inline: 3rem;
        font-size: 4vmin;
    }
    .btn-store .group {
        font-size: 6px;
    }
    .btn-store .group img {
        width: 50%;
    }
    .store-btn {
        justify-content: center;
    }
    .footer {
        font-size: 9px;
        font-weight: bolder;
        padding: 1rem;
        position: relative;
        top: 0%;
    }
    .store {
        font-size: 2.2vmin !important;
    }
    .appel {
        width: 2.2vmin !important;
    }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
    .logo {
        width: 13%;
    }
    .tutor-image {
        top: -104%;
        width: 75%;
    }
    .column-display {
        display: none;
    }
    .text-main {
        padding-inline: 3rem;
    }
    .text-seconde {
        padding-inline: 3rem;
    }
    .btn-store .group {
        font-size: 8px;
    }
    .store {
        font-size: 2.2vmin !important;
    }
    .btn-store .group img {
        width: 60%;
    }
    .store-btn {
        justify-content: center;
    }
    .footer {
        font-size: 9px;
        font-weight: bolder;
        padding: 1rem;
        position: relative;
        top: 0%;
    }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
    .logo {
        width: 10%;
    }
    .tutor-image {
        position: relative;
        max-width: 100%;
        max-height: 100%;
        margin-top: 50%;
    }
}

@media only screen and (min-width: 600px) {
    html {
        zoom: .8;
    }
    .store {
        font-size: 2.2vmin !important;
    }
    .appel {
        width: 2.2vmin !important;
    }
}

@media only screen and (min-width: 800px) {
    html {
        zoom: .9;
    }
    .store {
        font-size: 2.2vmin !important;
    }
    .appel {
        width: 2.2vmin !important;
    }
}

@media only screen and (min-width: 1000px) {
    html {
        zoom: 1;
        /* width: 101vw !important; */
    }
}

@media screen and (min-width:1400px) {
    .store {
        font-size: 2.2vmin !important;
    }
    .appel {
        width: 2.2vmin !important;
    }
}

.left-chat img,
.right-chat img {
    width: 50px;
    height: 50px;
    float: left;
    margin: 0px 10px;
}

.OT_edge-bar-item {
    display: none !important
}

.mysr-form-label {
    color: #fff !important;
}